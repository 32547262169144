import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import TextRenderer from './components/TextRenderer';
import PaperRenderer from './components/PaperRenderer';
import './App.css';

const styles = [
  { name: 'Classic', fontFamily: 'Arial', fontSize: 24, fill: 'black', background: 'white' },
  { name: 'Neon', fontFamily: 'Brush Script MT', fontSize: 32, fill: '#ff00ff', background: '#000033', shadowColor: '#ff00ff', shadowBlur: 10 },
  { name: 'Vintage', fontFamily: 'Courier New', fontSize: 20, fill: '#8B4513', background: '#F4A460' },
  { name: 'Minimalist', fontFamily: 'Helvetica', fontSize: 28, fill: '#333333', background: '#f0f0f0' },
  { name: 'Handwritten', fontFamily: 'Comic Sans MS', fontSize: 26, fill: '#4169E1', background: '#FFFACD' },
];

const engines = [
  { name: 'React', component: TextRenderer },
  { name: 'Paper.js', component: PaperRenderer },
];

const App = () => {
  const [inputText, setInputText] = useState('');
  const [activeEngine, setActiveEngine] = useState('React');

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const fileContent = reader.result;
        setInputText(fileContent);
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'text/plain' });

  const handleDownload = (format, style) => {
    // Implement download functionality here
    console.log(`Downloading ${format} for style: ${style.name}`);
  };

  const ActiveRenderer = engines.find(engine => engine.name === activeEngine).component;

  return (
    <div className="app">
      <div className="container">
        <h1 className="title">PrettyNotes</h1>
        <p className="subtitle">Transform your text into beautiful styles</p>
        

        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter your text here"
          className="search-bar"
        />

        <div className="engine-selector">
          {engines.map(engine => (
            <button
              key={engine.name}
              onClick={() => setActiveEngine(engine.name)}
              className={`engine-button ${activeEngine === engine.name ? 'active' : ''}`}
            >
              {engine.name}
            </button>
          ))}
        </div>

        <div className="results">
          {styles.map((style) => (
            <div key={style.name} className="result-item">
              <h3>{style.name}</h3>
              <div className="text-preview" data-style={style.name}>
                <ActiveRenderer text={inputText} style={style} />
              </div>
              <div className="download-buttons">
                <button onClick={() => handleDownload('png', style)}>Download PNG</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;