import React, { useEffect, useRef } from 'react';
import Paper from 'paper';

const PaperRenderer = ({ text, style }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    Paper.setup(canvas);

    const textItem = new Paper.PointText({
      point: new Paper.Point(140, 50),
      content: text || 'Type something...',
      fillColor: style.fill,
      fontFamily: style.fontFamily,
      fontSize: style.fontSize,
      justification: 'center'
    });

    const background = new Paper.Path.Rectangle({
      point: [0, 0],
      size: [280, 100],
      fillColor: style.background,
      radius: 5
    });

    background.sendToBack();

    Paper.view.draw();

    return () => {
      Paper.project.clear();
    };
  }, [text, style]);

  return <canvas ref={canvasRef} width={280} height={100} />;
};

export default PaperRenderer;
