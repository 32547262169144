import React from 'react';

const TextRenderer = ({ text, style }) => {
  return (
    <div
      style={{
        fontFamily: style.fontFamily,
        fontSize: style.fontSize,
        color: style.fill,
        backgroundColor: style.background,
        width: '280px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        boxShadow: style.shadowColor ? `0 0 10px ${style.shadowColor}` : 'none',
      }}
    >
      {text || 'Type something...'}
    </div>
  );
};

export default TextRenderer;
